import React, { useState } from "react";
import { Box, TextField, Button } from "@mui/material";
import { GoLocation } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import autoImage from "../assests/imges/auto.svg";

const Auto = () => {
  const [zipcode, setZipcode] = useState("");
  const [error, setError] = useState(null);

  let navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(zipcode.length);
    if (isNaN(zipcode) || zipcode.length < 5 || zipcode.length > 5) {
      console.log("Enter Valid Zipcode.");
      setError({ zipcodeError: "Enter Valid Zipcode." });
      return;
    }
    navigate(`/insurance/life-insurance/${zipcode}`);
    setError(null);
    return;
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 ">
            <h1 className="main-title m-top">Auto Insurance Quotes</h1>
            <h4>Find affordable car insurance by comparing quotes online!</h4>

            <form onSubmit={onSubmit}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginTop: "3rem",
                }}
              >
                <GoLocation
                  size={20}
                  style={{ marginRight: "5px", marginBottom: "5px" }}
                  color="#004B8D"
                />
                <TextField
                  onChange={(e) => {
                    setZipcode(e.target.value);
                    setError(null);
                  }}
                  value={zipcode}
                  label="ZIP Code"
                  variant="standard"
                />

                <Button type="submit" variant="contained" disableElevation>
                  Get a Quote
                </Button>
              </Box>
              {error && (
                <span className="text-danger">{error.zipcodeError}</span>
              )}
            </form>
            <p className="text-field-bottom-p">
              This Auto rate and coverage are provided and serviced by third
              parties.
            </p>
            <h4 className="ml-top primary-heading">
              <span>Get Free</span> Car Insurance Quotes Fast from the most
              trusted companies.
            </h4>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <img
              width={600}
              height={600}
              className="product-icon"
              src={autoImage}
              alt="Pro Insurance Hub Life logo"
            />
          </div>
        </div>
      </div>
      <div className="life-about-section">
        <div className="container">
          <h2 className="text-center pl-top">
            What is an auto insurance quote?
          </h2>
          <div className="border-line"></div>
          <p>
            An auto insurance quote is an estimate of what you can expect to pay
            for auto insurance coverage. Insurance quotes are determined by
            information such as your driving history, where you live, the type
            of car that you drive and a number of other personal and
            environmental factors. Each insurance company writes its auto
            policies based on different criteria, so no two insurance quotes are
            alike.
          </p>
          <h3>Types of Auto Insurance</h3>
          <p>
            When it comes to car insurance, drivers should consider a
            smorgasbord of possibilities. While one type of auto insurance
            policy may cover a single aspect, another type protects you from
             others.
          </p>
          <p>
            Before sitting down with licensed insurance agent, understand the types of auto
            insurance coverage available:
          </p>
          <ul>
            <li>
              <strong>Collision</strong>: when you are at fault in an accident,
              you need liability to cover the people you’ve hit. However,
              liability coverage alone won’t cover the damage to your car
            </li>
            <li>
              <strong>Bodily injury liability</strong> (<strong>BIL</strong>):
              covers the medical expenses of anyone injured in an accident that
              you caused. Often bodily injury liability policies are described
              as the 20/50 or a 100/300 policy – the number on the left is the
              maximum amount the policy will pay for one person’s injuries and
              the number on the right is the total amount the policy will pay
              for all injuries total.
            </li>
            <li>
              <strong>Property damage liability</strong>: if you caused the
              accident, this policy pays for any damage done to the vehicle
            </li>
            <li>
              <strong>Uninsured/underinsured motorist coverage</strong>: pays
              for costs in the event you are hit by a motorist who is either
              uninsured or underinsured
            </li>
            <li>
              <strong>Comprehensive Car Insurance</strong>: coverage if your car
              is stolen or damaged (not due to an accident)
            </li>
          </ul>
          <p>
            An experienced auto insurance
            agent can help you cut through the clutter.
          </p>
          <p>
            Ask for more information about auto insurance policies and request a
            quote online.
          </p>
          <h3>How Much Auto Insurance Do You Need?</h3>
          <p>
            While no driver wants to be underinsured, no one wants to pay more
            than they have to either. Licensed insurance agents recommend that you
            make sure you are covered for an equal amount of your total assets
            (add up the dollar values of your home, car, savings and
            investments). Additionally all drivers should consider carrying
            specific types of auto insurance coverage:
          </p>
          <ul>
            <li>
              Because you can’t control whether an uninsured or underinsured
              motorist hits you, consider adding underinsured/uninsured coverage
              to cover possible costs
            </li>
            <li>
              Collision and comprehensive coverage is vital for any vehicle
              owner particularly considering the cost of vehicles these days
            </li>
          </ul>
          <p>
            Visit with a friendly auto licensed insurance agent at Pro Insurance Hub for
            a customized car insurance policy to meet your specific needs,
            especially when it comes to finding the most affordable auto
            insurance policies.
          </p>
        </div>
      </div>
      <div className="ready-to-buy">
        <h1>Ready to shop for an Auto insurance?</h1>
        <p>
          Find the insurance you need and save by shopping from trusted
          insurers.
        </p>
        <Button
          variant="contained"
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }}
        >
          Get Quotes
        </Button>
        <p className="ready-to-buy-social">
          Ask us a question and we'll get back to you as soon as we can. And
          make sure you connect so we can stay friends!
        </p>
        <p className="contact-p"> Email us at contact@proInsuranceHub.com</p>
      </div>
    </>
  );
};

export default Auto;
