import React from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import logo from "../assests/imges/logo.svg";

const Header = () => {
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="light"
        variant="light"
        className="navbar shadow-sm"
        sticky="top"
      >
        <Container>
          <Navbar.Brand className="fw-bolder" as={Link} to="/">
            <img width={150} src={logo} alt="Pro Insurance Hub Life logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto my-2 my-lg-0">
              <Nav.Link className="fw-bold" as={Link} to="/insurance/life">
                Life
              </Nav.Link>
              <Nav.Link className="fw-bold" as={Link} to="/insurance/auto">
                Auto
              </Nav.Link>

              <Nav.Link className="fw-bold" as={Link} to="/insurance/home">
                Home
              </Nav.Link>

              <Nav.Link className="fw-bold" as={Link} to="/insurance/medicare">
                Medicare
              </Nav.Link>

              {/* <NavDropdown
                className="fw-bold"
                title="Services"
                id="basic-nav-dropdown"
              >
                <Container>
                  <Row>
                    <Col xs={12} md={12} lg={4}>
                      <h6 className="dropdown-heading">Life</h6>
                      <Nav.Link className="dropdown-link" as={Link} to="/life">
                        Life Lnsurance guide
                      </Nav.Link>
                      <Nav.Link className="dropdown-link" as={Link} to="/life">
                        Types of Life Insurance
                      </Nav.Link>
                    </Col>

                    <Col xs={12} md={12} lg={4}>
                      <h6 className="dropdown-heading">Auto</h6>
                      <Nav.Link className="dropdown-link" as={Link} to="/life">
                        Buy car Insurance
                      </Nav.Link>
                      <Nav.Link className="dropdown-link" as={Link} to="/life">
                        Types of Life Insurance
                      </Nav.Link>
                    </Col>

                    <Col xs={12} md={12} lg={4}>
                      <h6 className="dropdown-heading">Medicare</h6>
                      <Nav.Link className="dropdown-link" as={Link} to="/life">
                        Learn About Medicare
                      </Nav.Link>
                      <Nav.Link className="dropdown-link" as={Link} to="/life">
                        Eligibility
                      </Nav.Link>
                      <Nav.Link className="dropdown-link" as={Link} to="/life">
                        Enrollment
                      </Nav.Link>
                    </Col>
                  </Row>
                </Container>

                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Get Free Quotes
                </NavDropdown.Item>
              </NavDropdown> */}
            </Nav>
            <Nav className="me-2">
              <Button as={Link} to="/insurance" variant="outline-dark">
                Get Free Quotes
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
