import React, { useState, useEffect } from "react";
import formImage from "../assests/imges/form.svg";
import AlertDialog from "../components/AlertDialog";
import { Button, CircularProgress } from "@mui/material";

const sleep = (time) => new Promise((acc) => setTimeout(acc, time));

const Insurance = () => {
  const initialValues = {
    name: "",
    zipcode: "",
    email: "",
    phone: "",
    product: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [error, setError] = useState(initialValues);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isValidate, setIsValidate] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    setError(validate(formValues));
  };

  useEffect(() => {
    if (Object.keys(error).length === 0) {
      setIsValidate(true);
    }
    console.log("useeffect");
  }, [Object.keys(error).length === 0]);

  const validate = ({ name, zipcode, email, phone, product }) => {
    let errors = {};
    if (!name) {
      errors.name = "Name is required!";
    }
    if (!zipcode) {
      errors.zipcode = "Zipcode is required!";
    }
    if (!email) {
      errors.email = "Email is required!";
    }
    if (!phone) {
      errors.phone = "Phone Number is required!";
    }
    if (!product) {
      errors.product = "Product is required!";
    }
    return errors;
  };

  return (
    <>
      <div className="container all-insurance">
        <h1 className="text-center mt-3">Get a Free Insurance Quote</h1>
        <div className="row">
          <div className="col-sm-12 col-md-4 col-lg-6">
            <img
              width={400}
              height={400}
              className="product-icon"
              src={formImage}
              alt="Pro Insurance Hub Life logo"
            />
          </div>
          <div className="col-sm-12 col-md-4 col-lg-6">
            <h6 className="text-center mt-3">
              Fill the Form below to receive your no-cost consultation with a
              licensed insurance agent
            </h6>
            <form className="insurance-form" onSubmit={onSubmit}>
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 form-group ">
                  <label>Your Name</label>
                  <input
                    value={formValues.name}
                    onChange={(e) =>
                      setFormValues({ ...formValues, name: e.target.value })
                    }
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Your Name"
                  />
                  {error.name && (
                    <span className="text-danger">{error.name}</span>
                  )}
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                  <label>ZipCode</label>
                  <input
                    value={formValues.zipcode}
                    onChange={(e) =>
                      setFormValues({ ...formValues, zipcode: e.target.value })
                    }
                    className="form-control-lg form-control"
                    type="text"
                    placeholder="Zipcode"
                  />
                  {error.zipcode && (
                    <span className="text-danger">{error.zipcode}</span>
                  )}
                </div>
                <br />

                <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                  <label>Email</label>
                  <input
                    value={formValues.email}
                    onChange={(e) =>
                      setFormValues({ ...formValues, email: e.target.value })
                    }
                    className="form-control-lg form-control"
                    type="email"
                    placeholder="Email"
                  />
                  {error.email && (
                    <span className="text-danger">{error.email}</span>
                  )}
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                  <label>Phone</label>
                  <input
                    value={formValues.phone}
                    onChange={(e) =>
                      setFormValues({ ...formValues, phone: e.target.value })
                    }
                    className="form-control-lg form-control"
                    type="text"
                    placeholder="Phone"
                  />
                  {error.phone && (
                    <span className="text-danger">{error.phone}</span>
                  )}
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                  <label>Product</label>
                  <select
                    value={formValues.product}
                    onChange={(e) =>
                      setFormValues({ ...formValues, product: e.target.value })
                    }
                    className="form-control form-control-lg"
                    placeholder="Choose Product"
                  >
                    <option value="">Choose Product</option>
                    <option value="life">Life</option>
                    <option value="auto">Auto</option>
                    <option value="home">Home</option>
                    <option value="medicare">Medicare</option>
                  </select>
                  {error.product && (
                    <span className="text-danger">{error.product}</span>
                  )}
                </div>
              </div>
              <div className="text-center button">
                <Button
                  style={{ marginBottom: "2rem" }}
                  startIcon={
                    isValidate ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={isValidate}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Get Free Quotes
                </Button>
                {/* <button type="submit" className="btn btn-primary mb-2">
                  Get Free Quotes
                </button> */}
              </div>

              <p>
                By your clicking the "Get Free Quotes" button above and
                submitting your online quote request to ProInsuranceHub, you are
                agreeing by your electronic signature to give ProInsuranceHub
                your prior express written consent and continuing established
                business relationship permission to call you at each cell and
                residential phone number you provided in your online quote
                request, and any other subscriber or user of these phone
                numbers, using an automatic dialing system and pre-recorded and
                artificial voice messages any time from and after your inquiry
                to ProInsuranceHub Services for purposes of all federal and
                state telemarketing and Do-Not-Call laws and your prior
                affirmative written consent to email you at the email address(s)
                you provided in your online quote request, in each case to
                market our products and services to you and for all other
                purposes. Your consent is not required to get a quote or
                purchase anything from TProInsuranceHub, and you may instead
                reach us by phone at 1-307-243-3424.
              </p>
              {Object.keys(error).length === 0 && isValidate && (
                <AlertDialog completed={isValidate} />
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Insurance;
