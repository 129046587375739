import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  Button,
  Box,
  Stepper,
  Step,
  StepLabel,
  Grid,
  CircularProgress,
} from "@mui/material";
import { Field, Form, Formik, FormikConfig, FormikValues } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import "yup-phone";
import AlertDialog from "../../components/AlertDialog";

const sleep = (time) => new Promise((acc) => setTimeout(acc, time));

const LifeInsurance = () => {
  let { zipcode } = useParams();
  return (
    <div className="insurance-form">
      <Card className="row justify-content-center">
        <CardContent className="col-lg-5 col-md-8 col-sm-10">
          <FormikStepper
            initialValues={{
              firstName: "",
              lastName: "",
              dob: "",
              zipcode: zipcode,
              phone: "",
              email: "",
            }}
            onSubmit={async (values) => {
              await sleep(3000);
              console.log("values", values);
            }}
          >
            <FormikStep
              className="stepper"
              label="Personal Info"
              validationSchema={Yup.object().shape({
                firstName: Yup.string()
                  .min(2, "Too Short!")
                  .max(50, "Too Long!")
                  .required("First Name is Required!"),
                lastName: Yup.string()
                  .min(2, "Too Short!")
                  .max(50, "Too Long!")
                  .required("Last Name is Required!"),
   })}
            >
              <Box paddingTop={8} paddingBottom={2}>
                <Field
                  fullWidth
                  name="firstName"
                  component={TextField}
                  label="First Name"
                />
              </Box>
              <Box paddingY={2}>
                <Field
                  fullWidth
                  name="lastName"
                  component={TextField}
                  label="Last Name"
                />
              </Box>
              <Box paddingY={2}>
                <Field
                  type="date"
                  fullWidth
                  name="dob"
                  component={TextField}
                  label="Date of Birth"
                />
              </Box>
            </FormikStep>
            <FormikStep
              label="Location"
              validationSchema={Yup.object().shape({
                zipcode: Yup.string()
                  .required("Zipcode is Required!")
                  .matches(/^[0-9]+$/, "Must be only digits!")
                  .min(5, "Must be valid zipcode!")
                  .max(5, "Must be valid zipcode!"),
              })}
            >
              <Box paddingTop={18} paddingBottom={2}>
                <Field
                  className="text-feild-mb"
                  fullWidth
                  name="zipcode"
                  component={TextField}
                  label="ZipCode"
                />
              </Box>
            </FormikStep>
            <FormikStep
              label="Contact Info"
              validationSchema={Yup.object().shape({
                phone: Yup.string()
                  .phone("US", true, "Phone number is invalid!")
                  .matches(/^[0-9]+$/, "Must be only digits!")
                  .required(),
              })}
            >
              <Box paddingTop={18} paddingBottom={2}>
                <Field
                  className="text-feild-mb"
                  fullWidth
                  name="phone"
                  component={TextField}
                  label="Phone Number"
                />
              </Box>
            </FormikStep>

            <FormikStep
              label="Finish"
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Invalid email!")
                  .required("Email is Required"),
              })}
            >
              <Box paddingTop={18} paddingBottom={2}>
                <Field
                  className="text-feild-mb"
                  fullWidth
                  name="email"
                  component={TextField}
                  label="Email"
                />
              </Box>
            </FormikStep>
          </FormikStepper>
        </CardContent>
      </Card>
    </div>
  );
};

export default LifeInsurance;

export function FormikStep({ children }) {
  return <>{children}</>;
}

export const FormikStepper = ({ children, ...props }) => {
  const childrenArray = React.Children.toArray(children);
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];
  const [completed, setCompleted] = useState(false);

  const isLastStep = () => {
    return step === childrenArray.length - 1;
  };

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await props.onSubmit(values, helpers);
          setCompleted(true);
        } else {
          setStep((s) => s + 1);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form autoComplete="off">
          <Stepper activeStep={step} alternativeLabel>
            {childrenArray.map((child, index) => (
              <Step
                key={child.props.label}
                completed={step > index || completed}
              >
                <StepLabel>{child.props.label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {currentChild}
          <Grid container spacing={2}>
            {step > 0 ? (
              <Grid item>
                <Button
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  onClick={() => setStep((s) => s - 1)}
                >
                  Back
                </Button>
              </Grid>
            ) : null}
            <Grid item>
              <Button
                style={{ marginBottom: "10rem" }}
                startIcon={
                  isSubmitting ? <CircularProgress size="1rem" /> : null
                }
                disabled={isSubmitting}
                variant="contained"
                color="primary"
                type="submit"
              >
                {isSubmitting
                  ? "Submitting"
                  : isLastStep()
                  ? "Get Free Quotes"
                  : "Next"}
              </Button>
            </Grid>
            {isLastStep() && (
              <p className="text-justify font-weight-bold tcpa">
                By clicking the "Get Free Quotes" button above and submitting
                your online quote request to ProInsuranceHub, you are agreeing
                by your electronic signature to give ProInsuranceHub,
                <Link to="/partner-list">Insurance Partners</Link> and The
                Medicare Helpline, your prior express written consent and
                continuing established business relationship permission to text
                you as applicable or call you at the phone number you provided
                in your online quote request, and any other subscriber or user
                of this phone number, using an automatic dialing system and
                prerecorded and artificial voice messages any time from and
                after your inquiry to ProInsuranceHub for purposes of all
                federal and state telemarketing and Do-Not-Call laws and your
                prior affirmative written consent to email you at the email
                address(s) you provided in your online quote request, in each
                case to market our products and services to you and for all
                other purposes. Your consent is not required to get a quote or
                purchase anything from ProInsuranceHub, and you may instead
                reach us by phone at 1-307-243-3424
              </p>
            )}
          </Grid>
          {completed && <AlertDialog completed />}
        </Form>
      )}
    </Formik>
  );
};
