import React, { useState } from "react";
import { Box, TextField, Button } from "@mui/material";
import { GoLocation } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import medicareImage from "../assests/imges/medicare.svg";

const Medicare = () => {
  const [zipcode, setZipcode] = useState("");
  const [error, setError] = useState(null);

  let navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(zipcode.length);
    if (isNaN(zipcode) || zipcode.length < 5 || zipcode.length > 5) {
      console.log("Enter Valid Zipcode.");
      setError({ zipcodeError: "Enter Valid Zipcode." });
      return;
    }
    navigate(`/insurance/life-insurance/${zipcode}`);
    setError(null);
    return;
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 ">
            <h1 className="main-title m-top">Medicare Insurance Quotes</h1>
            <h4>Customizable plans to fit your needs and budget.</h4>

            <form onSubmit={onSubmit}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginTop: "3rem",
                }}
              >
                <GoLocation
                  size={20}
                  style={{ marginRight: "5px", marginBottom: "5px" }}
                  color="#004B8D"
                />
                <TextField
                  onChange={(e) => {
                    setZipcode(e.target.value);
                    setError(null);
                  }}
                  value={zipcode}
                  label="ZIP Code"
                  variant="standard"
                />

                <Button type="submit" variant="contained" disableElevation>
                  Get a Quote
                </Button>
              </Box>
              {error && (
                <span className="text-danger">{error.zipcodeError}</span>
              )}
            </form>
            <p className="text-field-bottom-p">
              This medicare rate and coverage are provided and serviced by third
              parties.
            </p>
            <h4 className="ml-top primary-heading">
              <span>Find </span>Affordable Medicare Advantage, Medicare
              Prescription Drug, and Medicare Supplement insurance plans.
            </h4>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <img
              width={600}
              height={600}
              className="product-icon"
              src={medicareImage}
              alt="Pro Insurance Hub Life logo"
            />
          </div>
        </div>
      </div>
      <div className="life-about-section">
        <div className="container">
          <h2 className="text-center pl-top">Introduction to Medicare?</h2>
          <div className="border-line"></div>
          <p>
            Medicare is the federal government program that provides health care
            coverage (health insurance) if you are 65+, under 65 and receiving
            Social Security Disability Insurance (SSDI) for a certain amount of
            time, or under 65 and with End-Stage Renal Disease (ESRD). The
            Centers for Medicare & Medicaid Services (CMS) is the federal agency
            that runs Medicare. The program is funded in part by Social Security
            and Medicare taxes you pay on your income, in part through premiums
            that people with Medicare pay, and in part by the federal budget.
          </p>
          <h3>What's The Difference Between Medicare And Medicaid?</h3>
          <p>
            Many people still don't know the difference between Medicare and
            Medicaid . They both sound the same, so what's the difference?
            <br />
            <br />
            Both are federal health insurance plans designed to give affordable
            or no-cost health insurance to qualifying individuals who are unable
            to pay a premium for private insurance plans. The main difference is
            that traditionally Medicare covers benefits for those over 65, while
            Medicaid is a free or low-cost insurance plan designed to provide
            basic coverage and benefits for low-income people.
          </p>
          <h3>Medicare Eligibility</h3>
          <p>
            Although the Medicare program primarily provides coverage for
            seniors, there are a few other extenuating situations that would
            allow somebody younger to be eligible for Medicare services. These
            include:
          </p>
          <ul>
            <li>
              <p>
                Younger individuals who have disabilities or a serious health
                condition.
              </p>
            </li>
            <li>
              <p>
                Those who have already been given their social security
                benefits.
              </p>
            </li>
            <li>
              <p>
                Individuals who benefit from the Railroad Retirement Board's
                disability program.
              </p>
            </li>
            <li>
              <p>
                Patients who are on kidney dialysis or who need a kidney
                transplant.
              </p>
            </li>
          </ul>
          <h3>What Does Medicare Cover?</h3>
          <p>
            Medicare has 5 main healthcare plans for seniors that are 65 or
            older as well as people with disabilities and several chronic health
            conditions, in a nutshell:
          </p>
          <ul>
            <li>
              <p> Medicare Part A covers basic hospitalization.</p>
            </li>
            <li>
              <p>
                Medicare part B provides coverage for doctor visits, diagnostic
                tests and their likes.
              </p>
            </li>
            <li>
              <p>
                Medicare Part C (also called Medicare Advantage) combines both
                Medicare Part A and Medicare Part B coverage with additional
                benefits.
              </p>
            </li>
            <li>
              <p> Medicare Part D covers prescription drugs.</p>
            </li>
            <li>
              <p>
                Medicare supplement plans such as Medigap are private coverages
                and it covers out-of-pocket expenses such as co-pays,
                co-insurance, and deductibles.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="ready-to-buy">
        <h1>Ready to shop for Medicare insurance?</h1>
        <p>
          Find the insurance you need and save by shopping from trusted
          insurers.
        </p>
        <Button
          variant="contained"
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }}
        >
          Get Quotes
        </Button>
        <p className="ready-to-buy-social">
          Ask us a question and we'll get back to you as soon as we can. And
          make sure you connect so we can stay friends!
        </p>
        <p className="contact-p"> Email us at contact@proInsuranceHub.com</p>
      </div>
      <div className="text-center">
        <p className="contact-p px-4 bg-secondary text-light py-4">
          We do not offer every plan available in your area. Any information we
          provide is limited to those plans we do offer in your area. Please
          contact Medicare.gov or 1–800–MEDICARE (TTY users should call
          1-877-486-2048) 24 hours a day/7 days a week to get information on all
          of your options
        </p>
      </div>
    </>
  );
};

export default Medicare;
