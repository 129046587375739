import React from "react";
import contactImage from "../assests/imges/contact.svg";

const Contact = () => {
  return (
    <>
      <h1 className="text-center bg-secondary py-5 text-light">Contact Us</h1>
      <div className="contact-us container">
        <h4 className="text-center  my-4">
          Whether you have a general question or are looking for more
          information, we can help. You can contact us by filling the form
          below.
        </h4>
        <div className="row">
          <div className="col-sm-12 col-md-8 col-lg-8">
            <form className="contact-form">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                  <label>Your Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Name"
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                  <label>ZipCode</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Zipcode"
                  />
                </div>
                <br />

                <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                  <label>Email</label>
                  <input
                    className="form-control"
                    type="email"
                    placeholder="Email"
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                  <label>Phone</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Phone"
                  />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                  <label>Product</label>
                  <select class="form-control" placeholder="Choose Product">
                    <option value="">Choose Product</option>
                    <option value="life">Life</option>
                    <option value="auto">Auto</option>
                    <option value="home">Home</option>
                    <option value="medicare">Medicare</option>
                    <option value="medicare">Other</option>
                  </select>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                  <label>Comment</label>
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                  ></textarea>
                </div>
              </div>
              <div className="text-center button my-4">
                <button type="submit" class="btn btn-primary mb-2">
                  Get Free Quotes
                </button>
              </div>
            </form>
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4">
            <img
              width={400}
              height={400}
              className="product-icon"
              src={contactImage}
              alt="Pro Insurance Hub Life logo"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
