import React from "react";
const TopHeader = () => {
  return (
    <div className="top-header">
      <p>
        Speak with a Licensed Insurance Agent, M-F 6am-5pm PT|307-243-3424, TTY
        711
      </p>
    </div>
  );
};

export default TopHeader;
