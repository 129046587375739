import React from "react";
import { Link } from "react-router-dom";

const Privacy = () => {
  return (
    <>
      <h1 className="text-center bg-secondary py-5 text-light">
        Terms and Conditions
      </h1>

      <div class="privacy-page">
        <h2 dir="ltr">IMPORTANT NOTE REGARDING WEBSITE CONTENT</h2>
        <p>
          The information and content (collectively, “Content”) on this website
          is for your general educational information only. The Content cannot,
          and is not intended to, replace the relationship that you have with
          your health care professionals. The Content on this website should not
          be considered medical advice and is not intended as medical advice.
          You should always talk to your health care professionals for diagnosis
          and treatment, including information regarding which drugs or
          treatment may be appropriate for you. None of the Content on this
          website represents or warrants that any particular drug or treatment
          is safe, appropriate or effective for you. Health information changes
          quickly. Therefore, you should always confirm information with your
          health care professionals.
        </p>
        <p>
          This website is intended for a United States audience. If you live
          outside the U.S., you may see information on this website about
          products or therapies that are not available or authorized in your
          country.
        </p>
        <p dir="ltr">
          The Content may include information regarding therapeutic and generic
          alternatives for certain prescription drugs and may describe uses for
          products or therapies that have not been approved by the Food and Drug
          Administration. This Content is for informational, cost-comparison
          purposes only. It is not medical advice and does not replace
          consultation with a doctor, pharmacist or other health care
          professional. Talk to your health care provider to determine if an
          alternative prescription drug is right for you.
        </p>
        <h4 dir="ltr">Required Disclosures</h4>
        <p dir="ltr">
          Read more about <Link to="/privacy">required disclosures</Link> and
          information relating to your use of this website.
        </p>
        <h4 dir="ltr">Agreement and Terms</h4>
        <p dir="ltr">
          These website Terms and Conditions (“Terms”) describe the rules for
          using this website. These Terms constitute a legally binding agreement
          between you, the person using this website, and ProInsuranceHub, Inc
          (“Pro Insurance Hub”). If you are helping another person use this
          website, these Terms constitute a legally binding agreement between
          both the helper and the person being helped and Pro Insurance Hub.
          Terms such as “we,” “us,” “our,” and “Company” refer to Pro Insurance
          Hub and its affiliate entities.
        </p>
        <p dir="ltr">
          By using this website, you agree to the most-recent Terms as well as
          the most-recent version of our{" "}
          <Link to="/privacy">Privacy Policies</Link> . We may change these
          Terms at any time, and such changes will be posted on this or a
          similar page of this website. It is your responsibility to review
          these Terms each time you use this website. By continuing to use this
          website, you consent to any changes to our Terms.
        </p>
        <p dir="ltr">
          By using this website, you acknowledge and agree that any activity on
          this website is subject to monitoring by the Company at any time, and
          that the Company may use the results of such monitoring without
          limitation, subject to applicable law.
        </p>
        <p dir="ltr">
          If you enter into any other agreement with the Company, for example
          regarding health plan coverage, then these Terms are in addition to
          the terms of such other agreement. Neither entering into this
          agreement, nor visiting this website, nor any of these Terms,
          guarantees that you are eligible to receive coverage under any plan
          offered through this website or otherwise.
        </p>
        <h4 dir="ltr">License to Use this Website and Content Ownership</h4>
        <p dir="ltr">
          Subject to these Terms, the Company grants you a personal,
          nontransferable, nonexclusive, revocable, limited license to view the
          Content on the website for the sole purpose of collecting information
          regarding our plan and related activities such as, if permitted on
          this website, applying for a plan. You may also print a reasonable
          number of copies of the Content for your personal use, but in such
          case you must reproduce all proprietary copyright and trademark
          notices. All rights, title and interest in and to the website,
          including the Content, and all intellectual property rights, including
          all copyright, trademark, patent and trade secret rights therein shall
          remain with the Company and our licensors and vendors, and no
          ownership interest is transferred to you or any other entity by virtue
          of making the Content available on the website, granting the foregoing
          licenses or entering into this Agreement. You also agree to review and
          abide by the Company’s policies regarding intellectual property
          rights, which include both copyright and trademark policies.
        </p>
        <p dir="ltr">
          In the event you choose to provide us with any feedback, suggestions,
          or similar communications, all such messages (collectively, “Feedback
          Messages”) will be considered non-personal, non-confidential (except
          for personal information as described in our Privacy Policies) and
          nonproprietary. You hereby grant the Company a perpetual,
          sublicensable, assignable, unrestricted, worldwide, royalty-free,
          irrevocable license to use, reproduce, display, perform, practice,
          modify, create derivative or collective works, transmit and distribute
          your Feedback Messages, in whole or in part, and including all
          intellectual property rights therein.
        </p>
        <p dir="ltr">
          Some websites operated by the Company include trademarks or logos
          belonging to other third-party licensors and are used pursuant to an
          agreement with such third parties.
        </p>
        <p dir="ltr">
          We may terminate this license at any time for any reason. If you
          breach any of these Terms, your license to the Content terminates
          immediately. Upon the termination of this license you must stop using
          this website, including all Content, and return or destroy all copies,
          including electronic copies, of the Content in your possession or
          control.
        </p>
        <h4 dir="ltr">Restrictions on Use of this Website</h4>
        <p dir="ltr">You agree:</p>
        <ul>
          <li dir="ltr">
            not to use this website or Content in any way not explicitly
            permitted by these Terms or the text of the website itself;
          </li>
          <li dir="ltr">
            not to copy, modify or create derivative works involving the
            Content, except you may print a reasonable number of copies for your
            personal use, provided that you reproduce all proprietary copyright
            and trademark notices;
          </li>
          <li dir="ltr">
            not to misrepresent your identity or provide us with any false
            information in any information-collection portion of this website,
            such as a registration or application page;
          </li>
          <li dir="ltr">
            not to take any action intended to interfere with the operation of
            this website;
          </li>
          <li dir="ltr">
            not to access or attempt to access any portion of this website to
            which you have not been explicitly granted access;
          </li>
          <li dir="ltr">
            not to share any password assigned to or created by you with any
            third parties or use any password granted to or created by a third
            party;
          </li>
          <li dir="ltr">
            not to directly or indirectly authorize anyone else to take actions
            prohibited in this section;
          </li>
          <li dir="ltr">
            to comply with all applicable laws and regulations while using this
            website or the Content. You represent and warrant that you are at
            least 13 years of age, and that if you are between 13 and 18 years
            of age a parent and/or guardian agrees to these Terms of Use on your
            behalf.
          </li>
        </ul>
        <h4 dir="ltr">Posting Messages, Comments or Content</h4>
        <p dir="ltr">
          Certain portions of this website may be configured to permit users to
          post messages, comments, or other content. Any such content is only
          the opinion of the poster, is no substitute for your own research, and
          should not be relied upon for any purpose. You agree not to post any
          content:
        </p>
        <ul>
          <li dir="ltr">
            which is profane, libelous, defamatory, obscene, pornographic,
            indecent, lewd, harassing, threatening, harmful, invasive of privacy
            or publicity rights, abusive, inflammatory, or otherwise
            objectionable; or
          </li>
          <li dir="ltr">
            the transmission of which could violate, or facilitate the violation
            of, any applicable law, regulation, or intellectual property rights.
          </li>
        </ul>
        <p dir="ltr">
          You are solely responsible for the content of any postings you submit
          and the Company assumes no responsibility or liability for any content
          submitted by you or any other website visitor. We may, but are not
          obligated to, restrict or remove any and all content from a message
          that we determine in our sole discretion violates these Terms or is
          otherwise harmful to us, our customers, or any third party. We reserve
          the right to remove the content you provide at any time, but you
          understand that we may preserve and access a backup-copy, and we may
          disclose the content if required to do so by law or in a good faith
          belief that such access, preservation, or disclosure is required by
          law or in the best interests of the Company.
        </p>
        <p dir="ltr">
          Any content you submit is for non-commercial use only. Any content you
          submit will be routed through the Internet and you understand and
          acknowledge that you have no expectation of privacy with regard to any
          content you submit. Never assume that you are anonymous and cannot be
          identified by your posts.
        </p>
        <p dir="ltr">
          If you do post content or submit material, and unless we indicate
          otherwise, you grant the Company a nonexclusive, royalty-free,
          perpetual, irrevocable, and fully sublicensable right to use,
          reproduce, modify, adapt, publish, translate, create derivative works
          from, distribute, and display such content throughout the world in any
          media. You grant the Company and any of its sublicensees the right to
          use the name that you submit in connection with such content, if they
          choose. You represent and warrant that you own or otherwise control
          all of the rights to the content that you post; that the content is
          accurate; that use of the content you supply does not violate these
          Terms and will not cause injury to any person or entity; and that you
          will indemnify the Company or its affiliates for all claims resulting
          from content you supply.
        </p>
        <h4 dir="ltr">Copyright Infringement – DMCA Notice</h4>
        <p dir="ltr">
          The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides
          recourse for copyright owners who believe that material appearing on
          the Internet infringes their rights under US copyright law. If you
          believe in good faith that content or material on this website
          infringes a copyright owned by you, you (or your agent) may send the
          Company a notice requesting that the material be removed, or access to
          it blocked. This request should be sent to:
          DMCARegisteredAgent@uhc.com
        </p>
        <p dir="ltr">
          The notice must include the following information: (a) a physical or
          electronic signature of a person authorized to act on behalf of the
          owner of an exclusive right that is allegedly infringed; (b)
          identification of the copyrighted work claimed to have been infringed;
          (c) identification of the material that is claimed to be infringing or
          the subject of infringing activity; (d) the name, address, telephone
          number, and email address of the complaining party; (e) a statement
          that the complaining party has a good faith belief that use of the
          material in the manner complained of is not authorized by the
          copyright owner, its agent or the law; and (f) a statement that the
          information in the notification is accurate and, under penalty of
          perjury, that the complaining party is authorized to act on behalf of
          the owner of an exclusive right that is allegedly infringed. If you
          believe in good faith that a notice of copyright infringement has been
          wrongly filed against you, the DMCA permits you to send us a
          counter-notice. Notices and counter-notices must meet the then-current
          statutory requirements imposed by the DMCA. Notices and
          counter-notices with respect to the website should be sent to the
          address above.
        </p>
        <h4 dir="ltr">Unsolicited Idea Submission Policy</h4>
        <p dir="ltr">
          It is our policy not to accept or consider unsolicited ideas or offers
          to sell intellectual property. We ask that you please do not submit
          any such ideas or offers to us or our employees or contractors.
          <br />
          This policy is intended to avoid potential misunderstandings if our
          technology, products, or services may seem similar to a submission
          made to the company. If you still choose to make a submission to us,
          you agree as follows, regardless of any statements made in your
          submission:
        </p>
        <ul>
          <li dir="ltr">We owe you no compensation</li>
          <li dir="ltr">
            There is no duty of confidentiality between us and you
          </li>
          <li dir="ltr">
            Your submission automatically becomes our property, and we may use
            or redistribute the contents of that submission in any way for any
            purpose
          </li>
          <li dir="ltr">
            We are free to acquire, develop and sell services and products that
            may be competitive to those you offer or suggest
          </li>
          <li dir="ltr">
            It is your responsibility to protect your own intellectual property,
            you should not make a submission to us if you have concerns about
            intellectual property
          </li>
        </ul>
        <p dir="ltr">
          If you are uncertain about the meaning of this policy or the legal
          ramifications of submitting materials to us, you should consult with
          your attorney before making a submission.
        </p>
        <h4 dir="ltr">Changes to Website Content</h4>
        <p dir="ltr">
          We may change, add or remove some or all of the Content on this
          website at any time. In addition, please note that although our goal
          is to provide accurate information, certain features that may be
          offered through this website, such as participating healthcare
          providers, pricing information or other Content, may not be accurate
          or up to date. In addition, please note that features of any plan or
          plans described in this website may change over time as permitted by
          law, including benefit levels, items included in any formulary,
          pricing or lists of participating providers or other associated
          vendors.
        </p>
        <h4 dir="ltr">Links</h4>
        <p dir="ltr">
          While visiting this website you may click on a link to other websites
          and leave this website. We provide links to other websites that may
          contain information that may be useful or interesting to you. We do
          not endorse, nor are responsible for, the content, accuracy and
          accessibility of linked websites operated by third parties or for any
          of your dealings with such third parties. You are solely responsible
          for your dealings with such third parties and we encourage you to read
          the terms of use and privacy policies on such third-party websites.
        </p>
        <h4 dir="ltr">NO WARRANTIES</h4>
        <p dir="ltr">
          ALL CONTENT ON THIS WEBSITE IS PROVIDED TO YOU ON AN “AS IS”, “AS
          AVAILABLE” BASIS. THE COMPANY, ALL THIRD PARTIES, IF ANY, PROVIDING
          CONTENT FOR THIS WEBSITE, AND ALL THIRD PARTIES PROVIDING SUPPORT OR
          INFORMATION FOR THIS WEBSITE (COLLECTIVELY, “WEBSITE-RELATED-PARTIES”)
          HEREBY DISCLAIM ALL WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
          STATUTORY OR OTHERWISE INCLUDING BUT NOT LIMITED TO THE IMPLIED
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
          NON-INFRINGEMENT.
        </p>
        <p dir="ltr">
          THE WEBSITE-RELATED-PARTIES MAKE NO WARRANTY AS TO THE ACCURACY,
          COMPLETENESS, TIMELINESS, CORRECTNESS, OR RELIABILITY OF ANY CONTENT
          AVAILABLE THROUGH THIS WEBSITE. WITHOUT LIMITING THE FOREGOING, THE
          WEBSITE-RELATED-PARTIES SPECIFICALLY DO NOT REPRESENT OR WARRANT THAT
          ANY INFORMATION REGARDING PARTICULAR PLAN BENEFITS OR SCOPE OF
          COVERAGE IS ACCURATE OR COMPLETE. THE WEBSITE-RELATED-PARTIES MAKE NO
          REPRESENTATIONS OR WARRANTIES THAT USE OF THIS WEBSITE WILL BE
          UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT
          THIS WEBSITE OR THE TECHNOLOGY THAT MAKES IT AVAILABLE ARE FREE OF
          VIRUSES OR OTHER HARMFUL COMPONENTS. YOU ARE RESPONSIBLE FOR TAKING
          ALL PRECAUTIONS NECESSARY TO ENSURE THAT ANY CONTENT YOU MAY OBTAIN
          FROM THIS WEBSITE IS FREE OF VIRUSES AND ANY OTHER POTENTIALLY
          DESTRUCTIVE COMPUTER CODE.
        </p>
        <p dir="ltr">
          Some states do not allow limitations on implied warranties, so one or
          more of the above limitations may not apply to you. Check your local
          laws for any restrictions or limitations regarding the exclusion of
          implied warranties.
        </p>
        <h4 dir="ltr">LIMITATION OF LIABILITY</h4>
        <p dir="ltr">
          YOU AGREE THAT NONE OF THE WEBSITE-RELATED-PARTIES SHALL BE LIABLE FOR
          ANY DAMAGE RESULTING FROM YOUR USE OR INABILITY TO USE THIS WEBSITE OR
          THE CONTENT. THIS PROTECTION COVERS CLAIMS BASED ON WARRANTY,
          CONTRACT, TORT, STRICT LIABILITY, AND ANY OTHER LEGAL THEORY. THIS
          PROTECTION COVERS THE WEBSITE-RELATED-PARTIES, INCLUDING ALL
          AFFILIATES, AND THEIR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS. THIS
          PROTECTION COVERS ALL LOSSES INCLUDING, WITHOUT LIMITATION, DIRECT OR
          INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, AND PUNITIVE
          DAMAGES, PERSONAL INJURY/WRONGFUL DEATH, LOST PROFITS, OR DAMAGES
          RESULTING FROM LOST DATA OR BUSINESS INTERRUPTION. THE TOTAL,
          CUMULATIVE, LIABILITY OF THE WEBSITE-RELATED-PARTIES, INCLUDING ALL
          AFFILIATES, AND THEIR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS, IF
          ANY, FOR LOSSES OR DAMAGES SHALL BE LIMITED TO THE AMOUNT OF YOUR
          ACTUAL DAMAGES, NOT TO EXCEED U.S. $100.00. THE LIMIT OF LIABILITY MAY
          NOT BE EFFECTIVE IN SOME STATES. IN NO EVENT SHALL THE
          WEBSITE-RELATED-PARTIES, INCLUDING ALL AFFILIATES, AND THEIR OFFICERS,
          DIRECTORS, EMPLOYEES, AND AGENTS, BE LIABLE TO YOU FOR ANY LOSSES OR
          DAMAGES OTHER THAN THE AMOUNT DESCRIBED ABOVE. ALL OTHER DAMAGES,
          DIRECT OR INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, OR
          PUNITIVE, RESULTING FROM ANY USE OF THE WEBSITE OR CONTENT ARE
          EXCLUDED EVEN IF THE WEBSITE-RELATED-PARTIES HAVE BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES. YOU AGREE THAT YOU USE THIS WEBSITE AT
          YOUR OWN RISK. IF YOU ARE DISSATISFIED WITH THIS WEBSITE OR THE
          CONTENT, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE
          WEBSITE.
        </p>
        <p dir="ltr">
          The foregoing limitation of liability is not applicable to residents
          of New Jersey. With respect to residents of New Jersey, the Released
          Parties are not liable for any damages unless such damages are the
          result of our negligent or reckless acts or omissions; and the
          Released Parties are not, in any case, liable for indirect,
          incidental, special, consequential or punitive damages.
          <br />
          You acknowledge and agree that Company’s suppliers are third-party
          beneficiaries of these Terms, with the right to enforce the
          limitations of warranty and liability set forth herein with respect to
          the respective technology of such suppliers and Company.
        </p>
        <h4 dir="ltr">Governing Law and Statute of Limitations</h4>
        <p dir="ltr">
          The laws of the State of Texas govern these Terms and any cause of
          action arising under or relating to your use of the website, without
          reference to its choice-of-law principles. You agree that the only
          proper jurisdiction and venue for any dispute with the Company, or in
          any way relating to your use of this website, is in the state and
          federal courts in the State of Texas, U.S.A. You further agree and
          consent to the exercise of personal jurisdiction in these courts in
          connection with any dispute involving the Company or its employees,
          officers, directors, agents and providers. If any provision of these
          Terms is determined to be invalid under any applicable statute or rule
          of law, such provision is to that extent to be deemed omitted, and the
          balance of the Agreement shall remain enforceable.
        </p>
        <p dir="ltr">
          Before seeking legal recourse for any harm you believe you have
          suffered arising from or related to your use of this website, you
          agree to inform us in writing and to give us 30 days to cure the harm
          before initiating any action. You must initiate any cause of action
          within one year after the claim has arisen, or you will be barred from
          pursuing any cause of action.
        </p>
        <p dir="ltr">
          Unless you are a resident of New Jersey, you must initiate any cause
          of action within one year after the claim has arisen, or you will be
          barred from pursuing any cause of action.
        </p>
        <h4 dir="ltr">Electronic Signatures and System Requirements</h4>
        <p dir="ltr">
          By logging onto this website, you have signified your agreement to all
          of the Terms to use this website and shall have the same binding
          effect as it would had you signed the same agreement on paper. If you
          would like a paper copy of this agreement, you may obtain one by
          submitting a written request to the address under “Contact Us” section
          below. For optimum use of this website, you may need to utilize
          specific web browsers.
        </p>

        <h4 dir="ltr">Additional Terms</h4>
        <p dir="ltr">
          Your obligations under the following sections survive termination of
          this Agreement: Important Note Regarding Website Content; Agreement
          and Terms; portions of License to Use this Website and Content
          Ownership; Restrictions on Use of this Website; Posting Messages,
          Comments or Content; Copyright Infringement – DMCA Notice; Changes to
          Website Content; Links; NO WARRANTIES; LIMITATION OF LIABILITY;
          Governing Law and Statute of Limitations; Additional Terms. If any
          provision of these Terms is found to be invalid by any court having
          competent jurisdiction, the invalidity of such provision shall not
          affect the validity of the remaining provisions of these Terms, which
          shall remain in full force and effect. No waiver of any of these Terms
          shall be deemed a further or continuing waiver of such term or
          condition or any other term or condition. You agree to defend and
          indemnify, Website-Related-Parties and their subsidiaries, affiliates,
          officers, directors, employees, and agents, harmless from any claim,
          demand, or damage, including reasonable attorneys’ fees, arising out
          of or related to your breach of this Agreement or your use or misuse
          of the Content or website. You may not transfer or assign any rights
          or obligations under this Agreement. The Company may transfer or
          assign its rights and obligations under this Agreement.
        </p>
        <h4 dir="ltr">Effective Date</h4>
        <p dir="ltr">The Effective Date of these Terms is July 10, 2024.</p>
      </div>
    </>
  );
};

export default Privacy;
