import "./assests/css/style.css";
import React from "react";
import { Route, Routes } from "react-router-dom";

import TopHeader from "./components/TopHeader";
import Header from "./components/Header";
import Insurance from "./pages/Insurance";
import Home from "./pages/Home";
import Life from "./pages/Life";
import Auto from "./pages/Auto";
import Medicare from "./pages/Medicare";
import LifeInsurance from "./pages/insurance/life-insurance";
import PageNotFound from "./pages/PageNotFound";
import Footer from "./components/Footer";
import Index from "./pages/Index";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Ccpa from "./pages/Ccpa";
import PartnerList from "./pages/PartnerList";

const App = () => {
  return (
    <div>
      <TopHeader />
      <Header />
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/insurance" element={<Insurance />} />
        <Route path="/insurance/life" element={<Life />} />
        <Route path="/insurance/auto" element={<Auto />} />
        <Route path="/insurance/home" element={<Home />} />
        <Route path="/insurance/medicare" element={<Medicare />} />
        <Route path="/insurance/:type/:zipcode" element={<LifeInsurance />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/ccpa" element={<Ccpa />} />
        <Route path="/partner-list" element={<PartnerList />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
