import React from "react";
import { Link } from "react-router-dom";
import logo from "../assests/imges/logo.svg";

const Footer = () => (
  <footer className="page-footer font-small">
    <div className="border-line"></div>
    <div className="container text-md-left pt-4">
      <div className="row">
        <div className="col-md-6 mt-md-0 mt-3">
          <div className="">
            <img
              width={300}
              height={150}
              src={logo}
              alt="Pro Insurance Hub Life logo"
            />
          </div>
          <p>
            We offer insurance by phone, online and through our partners or
            independent agents.
          </p>
        </div>

        <hr className="clearfix w-100 d-md-none pb-0" />

        <div className="col-md-3 mb-md-0 mb-3">
          <h5 className="text-uppercase">Shop</h5>
          <ul className="footer-link list-unstyled">
            <li>
              <Link to="/insurance/life">Life Insurance</Link>
            </li>
            <li>
              <Link to="/insurance/auto">Auto Insurance</Link>
            </li>
            <li>
              <Link to="/insurance/home">Home Insurance</Link>
            </li>
            <li>
              <Link to="insurance/medicare">Medicare Insurance</Link>
            </li>
          </ul>
        </div>

        <div className="col-md-3 mb-md-0 mb-3">
          <h5 className="text-uppercase">Support</h5>
          <ul className="footer-link list-unstyled">
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
            <li>
              <a href="tel:+1-307-243-3424">1-307-243-3424</a>
            </li>
            <li>
              <a href="mailTo:support@proinsurancehub.com">
                support@proInsuranceHub.com
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div className="footer-privacy text-center">
      <ul>
        <li>
          <Link to="/contact">CONTACT US</Link>
        </li>
        <li>
          <Link to="/privacy">PRIVACY</Link>
        </li>
        <li>
          <Link to="/terms">TERMS & CONDITIONS</Link>
        </li>
        <li>
          <Link to="/ccpa">CCPA</Link>
        </li>
        <li>
          <Link to="/about">ABOUT US</Link>
        </li>
      </ul>
    </div>
    <div className="footer-copyright text-center my-1">
      <p className="text-center disclosures">
        DISCLOSURES:- This is an advertising referral and comparison service
        that matches consumers with prospective agents and companies depending
        upon your information. The operator of this website is not an insurance
        company, an agent, a provider, a broker, a representative, a bank, or a
        financial or lending institution. We do not represent any specific
        provider and do not provide insurance or financial quotes, or policies.
        Quotes are provided by independent participating companies that
        participate in network. Lowest advertised rates may vary and are not
        necessarily available from all participating service providers. We do
        not endorse or charge you for any product or service. Any compensation
        received is paid by participating providers and only for advertising
        services provided. This service is void where prohibited. We are not
        connected with or endorsed by the U.S. government or any federal
        programs. This service and these disclosures are provided to you for
        information purposes only and should not be used as a substitute for
        informed professional advice.
      </p>
      © 2020 Copyright:
      <Link to="https://ProInsuranceHub.com/"> ProInsuranceHub.com</Link>
    </div>
  </footer>
);

export default Footer;
