import React from "react";
import contactImage from "../assests/imges/contact.svg";

const About = () => {
  return (
    <>
      <h1 className="text-center bg-secondary py-5 text-light">
        About Pro Insurance Hub
      </h1>
      <div className="about-us container">
        <p className="mission my-4">
          <span className="">Our Mission</span> is to help people protect their
          most valuable assets and overall financial well-being by finding the
          best insurance for them at the best price in just minutes. We’re here
          to serve as a resource, answer questions and share unbiased price
          comparisons from some of the country’s leading insurance companies,
          giving individuals choice, value and peace of mind when it comes to
          buying insurance.
        </p>
        <h4 className="m-heading">
          Our customer promise drives every decision we make
        </h4>
        <p>
          We offer the lowest available price for each plan, plus no extra fees,
          unparalleled selection, and more. That's right — we're committed to
          delivering a superior shopping experience at no cost to you.
        </p>
        <h6>While we have grown, our initial mission has stayed the same:</h6>
        <ul>
          <li>Remove barriers to shopping for insurance</li>
          <li>Introduce more transparency and choice</li>
          <li>
            Take care of people; finding the protection you need at a price that
            fits your budget
          </li>
        </ul>
        <h2>Our Purpose</h2>
        <p>
          We’re not like just any insurance company. At Pro Insurance Hub, we’re
          dedicated to inspiring, protecting and restoring your dreams — because
          we believe a dream is the most valuable thing you’ll ever own. That’s
          why we make it our mission to give you the support and inspiration you
          need to realize your dreams.
        </p>
      </div>
    </>
  );
};

export default About;
