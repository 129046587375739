import React, { useState } from "react";
import { Box, TextField, Button } from "@mui/material";
import { GoLocation } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import homeImage from "../assests/imges/home.svg";

const Home = () => {
  const [zipcode, setZipcode] = useState("");
  const [error, setError] = useState(null);

  let navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(zipcode.length);
    if (isNaN(zipcode) || zipcode.length < 5 || zipcode.length > 5) {
      console.log("Enter Valid Zipcode.");
      setError({ zipcodeError: "Enter Valid Zipcode." });
      return;
    }
    navigate(`/insurance/life-insurance/${zipcode}`);
    setError(null);
    return;
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 ">
            <h1 className="main-title m-top">Home Insurance Quotes</h1>
            <h4>Protect your home and family, affordably</h4>

            <form onSubmit={onSubmit}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginTop: "3rem",
                }}
              >
                <GoLocation
                  size={20}
                  style={{ marginRight: "5px", marginBottom: "5px" }}
                  color="#004B8D"
                />
                <TextField
                  onChange={(e) => {
                    setZipcode(e.target.value);
                    setError(null);
                  }}
                  value={zipcode}
                  label="ZIP Code"
                  variant="standard"
                />

                <Button type="submit" variant="contained" disableElevation>
                  Get a Quote
                </Button>
              </Box>
              {error && (
                <span className="text-danger">{error.zipcodeError}</span>
              )}
            </form>
            <p className="text-field-bottom-p">
              This homeowners rate and coverage are provided and serviced by
              affiliated and third-party insurers.
            </p>
            <h4 className="ml-top primary-heading">
              <span>Get </span>a Free Homeowners Quote Instantly - Talk to an
              Agent.
            </h4>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <img
              width={600}
              height={600}
              className="product-icon"
              src={homeImage}
              alt="Pro Insurance Hub Life logo"
            />
          </div>
        </div>
      </div>
      <div className="life-about-section">
        <div className="container">
          <h2 className="text-center pl-top">
            What does homeowners insurance cover?
          </h2>
          <div className="border-line"></div>
          <p>
            We're here to protect your home and everything in it. Here are just
            a few ways you're covered with homeowners insurance from Pro
            Insurance Hub:
          </p>
          <ul class="bullets">
            <li>
              <strong>Dwelling coverage</strong>: Pays for damage to your home
              and anything attached to it, such as a garage or deck.
            </li>
            <br />
            <li>
              <strong>Other structures</strong>: Covers the detached structures
              around your home, including your driveway, patio, and more.
            </li>
            <br />
            <li>
              <strong>Personal property coverage</strong>: Covers your personal
              belongings, including furniture and clothing, if they're damaged
              or destroyed after a covered loss.
            </li>
            <br />
          </ul>
          <h3>Is homeowners insurance required?</h3>
          <p>
            Homeowners insurance isn't legally required, but most lenders will
            require you to buy insurance so your home, and their investment, is
            protected.
          </p>
          <h3>Should I take a home inventory?</h3>
          <p>
            Yes, taking the time to make a list of all your belongings can make
            filing a claim much faster and easier. It can also help you choose
            the right amount of personal property coverage.
          </p>
        </div>
      </div>
      <div className="ready-to-buy">
        <h1>Ready to shop for home insurance?</h1>
        <p>
          Find the insurance you need and save by shopping from trusted
          insurers.
        </p>
        <Button
          variant="contained"
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }}
        >
          Get Quotes
        </Button>
        <p className="ready-to-buy-social">
          Ask us a question and we'll get back to you as soon as we can. And
          make sure you connect so we can stay friends!
        </p>
        <p className="contact-p"> Email us at contact@proInsuranceHub.com</p>
      </div>
    </>
  );
};

export default Home;
