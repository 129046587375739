import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box, TextField, Button } from "@mui/material";
import { GoLocation } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import quotesImage from "../assests/imges/quotes.svg";

const Life = () => {
  const [zipcode, setZipcode] = useState("");
  const [error, setError] = useState(null);

  let navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(zipcode.length);
    if (isNaN(zipcode) || zipcode.length < 5 || zipcode.length > 5) {
      console.log("Enter Valid Zipcode.");
      setError({ zipcodeError: "Enter Valid Zipcode." });
      return;
    }
    navigate(`/insurance/life-insurance/${zipcode}`);
    setError(null);
    return;
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 ">
            <h1 className="main-title m-top" id="quotes">
              Life Insurance Quotes
            </h1>
            <h4>See how affordable a life insurance policy can be.</h4>

            <form onSubmit={onSubmit}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginTop: "3rem",
                }}
              >
                <GoLocation
                  size={20}
                  style={{ marginRight: "5px", marginBottom: "5px" }}
                  color="#004B8D"
                />
                <TextField
                  onChange={(e) => {
                    setZipcode(e.target.value);
                    setError(null);
                  }}
                  value={zipcode}
                  label="ZIP Code"
                  variant="standard"
                />

                <Button type="submit" variant="contained" disableElevation>
                  Get a Quote
                </Button>
              </Box>
              {error && (
                <span className="text-danger">{error.zipcodeError}</span>
              )}
            </form>
            <p className="text-field-bottom-p">
              This life rate and coverage are provided and serviced by third
              parties.
            </p>
            <h4 className="ml-top primary-heading">
              <span>You are </span>seconds away from getting the lowest life
              insurance rates in America
            </h4>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <img
              width={600}
              height={600}
              className="product-icon"
              src={quotesImage}
              alt="Pro Insurance Hub Life logo"
            />
          </div>
        </div>
      </div>
      <div className="life-about-section">
        <div className="container">
          <h2 className="text-center pl-top">What is life insurance?</h2>
          <div className="border-line"></div>
          <p>
            Life insurance is a contract in which a policyholder pays regular
            premiums in exchange for a lump-sum death benefit may be paid to the
            policyholder's beneficiaries. The lump-sum benefit is paid when the
            policyholder either passes away or a specific amount of time has
            passed. Life insurance policies can help provide financial security
            by replacing lost income and covering expenses.
          </p>
          <h3>What are the different types of life insurance?</h3>
          <p>
            There are many kinds of life insurance policies, but by far the most
            common ones are term and whole. Most people find term life insurance
            to be more practical — it is straightforward, affordable, and only
            lasts for as long as you need it. But the policy that’s best for you
            will depend on your individual circumstances, including your
            financial obligations, income, health, and lifestyle.
          </p>
          <p>
            Another type of life insurance you might encounter is universal life
            insurance. Like whole life insurance, universal insurance coverage
            lasts for life and has a cash value savings feature. But you can
            adjust the premiums or benefit of a universal life policy, depending
            on your cash value performance. Unless you’re a high earner looking
            to maximize your investments, term or whole life are usually a
            better option than universal.
          </p>
          <h3>Why do you need life insurance?</h3>
          <p>
            Life insurance helps you plan ahead and provides long-term financial
            security for your family when they would need it most. You can't put
            a dollar amount on your loved ones, but a term life insurance policy
            can help ensure their future is protected. Determine how much
            coverage you need and how long it's needed, and the Pro Insurance
            Hub and it's partners can provide an affordable life insurance
            policy that is the perfect fit for you and your family. Get a life
            insurance quote online or call us at 1-307-243-3424 and get the
            satisfaction of knowing your loved ones are protected.
          </p>
        </div>
      </div>
      <div className="ready-to-buy">
        <h1>Ready to shop for life insurance?</h1>
        <p>
          Find the insurance you need and save by shopping from trusted
          insurers.
        </p>
        <Button
          variant="contained"
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }}
        >
          Get Quotes
        </Button>
        <p className="ready-to-buy-social">
          Ask us a question and we'll get back to you as soon as we can. And
          make sure you connect so we can stay friends!
        </p>
        <p className="contact-p"> Email us at contact@proInsuranceHub.com</p>
      </div>
    </>
  );
};

export default Life;
