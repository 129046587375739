import React from "react";
import { Link } from "react-router-dom";
import { FaMoneyBillAlt, FaHandsHelping } from "react-icons/fa";
import { IoTime, IoCall } from "react-icons/io5";

import lifeInsImage from "../assests/imges/family.png";
import carInsImage from "../assests/imges/car-insurance.png";
import medicareInsImage from "../assests/imges/healthcare.png";
import homeInsImage from "../assests/imges/home-insurance.png";
import mobileImage from "../assests/imges/mobile.svg";
import AccordionComp from "../components/Accordian";

const Index = () => {
  return (
    <div>
      <section className="hero background-img">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6 top-section-left">
              <h1 className="main-title">
                Get Free Quotes from Trusted Companies.
              </h1>
              <p className="sub-title">
                See how much you could save! Let's get started
              </p>
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <Link to="/insurance/life" className="product-button">
                    <img
                      className="product-icon"
                      src={lifeInsImage}
                      alt="Pro Insurance Hub Life logo"
                      width="48px"
                      height="48px"
                    />
                    <p className="product-title">Life</p>
                  </Link>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6">
                  <Link to="/insurance/auto" className="product-button">
                    <img
                      className="product-icon"
                      src={carInsImage}
                      alt="Pro Insurance Hub Life logo"
                      width="48px"
                      height="48px"
                    />
                    <p className="product-title">Auto</p>
                  </Link>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6">
                  <Link to="/insurance/medicare" className="product-button">
                    <img
                      className="product-icon"
                      src={medicareInsImage}
                      alt="Pro Insurance Hub Life logo"
                      width="48px"
                      height="48px"
                    />
                    <p className="product-title">Medicare</p>
                  </Link>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6">
                  <Link to="/insurance/home" className="product-button">
                    <img
                      className="product-icon"
                      src={homeInsImage}
                      alt="Pro Insurance Hub Life logo"
                      width="48px"
                      height="48px"
                    />
                    <p className="product-title">Home</p>
                  </Link>
                </div>
              </div>

              <p className="p-underline">Select a product to get Quotes.</p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 top-section-right"></div>
          </div>
        </div>
      </section>
      <section className="why-us">
        <div className="container">
          <h1 className="text-center">
            Why <span className="company-name">Pro Insurance Hub</span>? Easy to
            shop, great selection & service
          </h1>
          <p className="text-justify mt-10">
            Many licensed insurance agents exclusively represent one specific insurance
            carrier. As a result, they cannot provide you with price comparisons
            of their products to similar products from other carriers. Pro
            Insurance Hub has been providing its customer in all US States, and
            around the country with exceptional insurance services for over 10
            years. Pro Insurance Hub
            offers coverage with multiple insurance carriers. So, rather than
            try to fit each customer with one option, we explore multiple
            options to find the perfect fit for each customer. This makes Pro
            Insurance Hub unique . Our
            commitment is to become a trusted advocate to each of our customers
            and to provide them with the most exceptional customer service
            possible.
          </p>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <div className="card">
                <FaMoneyBillAlt size={100} color="#004B8D" />
                <h5>You Don't Pay for Our Services</h5>
                <p>
                  You'll pay the same price as the insurance carriers with no
                  extra fees. Compare them all to find the best plan for you.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <div className="card">
                <IoTime size={100} color="#004B8D" />
                <h5>May Save Time</h5>
                <p>
                  Compare all the trusted carriers and find affordable rates,
                  without the spam. Our process is fast and secure.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <div className="card">
                <FaHandsHelping size={100} color="#004B8D" />
                <h5>We're here to Advise you</h5>
                <p>
                  If you need help with your plan, our licensed insurance agents
                  are available by chat, email, or phone.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="guide">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6">
              <img
                width={600}
                height={200}
                className="product-icon"
                src={mobileImage}
                alt="Pro Insurance Hub Life logo"
              />
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 right">
              <h1 className="text-center">Shop over the phone</h1>
              <h5 className="text-center">
                Speak with a licensed insurance agent and get knowledgable advice on choosing a
                plan that's right for you.
              </h5>
              <h5 className="text-center call">
                <IoCall size={40} color="#fff" />
                1-307-243-3424{" "}
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section className="card-section">
        <div className="container">
          <AccordionComp />
        </div>
      </section>
    </div>
  );
};

export default Index;
