import React from "react";

const Privacy = () => {
  return (
    <>
      <h1 className="text-center bg-secondary py-5 text-light">
        Privacy Policy
      </h1>

      <div className="privacy-page">
        <p>
          This Privacy Policy describes how ProInsuranceHub uses and protects
          the information you provide us as a visitor to this website or as our
          customer.
        </p>
        <p>
          We value your personal privacy and strive to protect it. If we ask you
          to provide any information by which you can be identified using this
          website, it will be used in strict accordance with this Privacy
          Policy.
        </p>
        <p>
          ProInsuranceHub owns the information collected on this website. We
          will not sell, disseminate, disclose, trade, transmit, transfer,
          share, lease or rent personally identifiable information to a third
          party not specifically authorized to receive your information except
          as we disclose to you in this Privacy Policy.
        </p>
        <h2>What We Collect</h2>
        <p>
          We will not collect personal information that is not voluntarily
          supplied by you. We will ask you to provide your personal information
          to us when you enroll for coverage and purchase an insurance policy
          from us, as well as when you contact us from this Web site. When you
          contact us from this website, you will provide us with certain
          personally identifiable information, including your email address and
          phone number.
        </p>
        <p>
          When you enroll for an insurance policy through ProInsuranceHub, we
          will ask you to provide your name, home address, mailing address,
          telephone number and email address. This information will be provided
          to the insurance company when you purchase an insurance policy so they
          can establish you as a policyholder. We will maintain a record of your
          information at the offices of ProInsuranceHub, so we can provide you
          with policyholder service.
        </p>
        <p>
          Each employee has been instructed on how to maintain the privacy of
          each customer, and the importance of protecting the customers’
          personal information. Each employee at ProInsuranceHub is required to
          sign and acknowledge a Confidentiality and Nondisclosure Agreement to
          maintain customers’ personal information.
        </p>
        <h2>Payment</h2>
        <p>
          When you purchase an insurance policy through Health Plan Options
          Today/Garland Financial Group. you will pay the premium with your
          credit card, personal check or cash. If you use your credit card to
          pay the premium, your credit card information is deleted from our
          records after your purchase has been authorized. A transaction number
          will be provided to you on your Binder of Insurance. You may use this
          transaction number as a reference to the credit card purchase. We do
          not keep a record of your credit card information. If you pay the
          premium using your personal check, we will not keep a copy of your
          check in the file.
        </p>
        <h2>What We Do With The Information We Gather</h2>
        <p>
          We may use personal information for internal record keeping; to
          provide services you request and process your transactions or to
          create, operate and improve our products, services, content and
          advertising.
        </p>
        <h2>How We Use Cookies</h2>
        <p>
          We use website browser software tools such as cookies and web server
          logs to gather information about your browsing activities, in order to
          constantly improve our website and make your visit to the website more
          enjoyable. Cookies help us collect important business and technical
          statistics. The information in the cookies lets us trace the paths
          followed by users to our website as they move from one page to
          another. Web server logs allow us to count how many people visit our
          website and evaluate our website’s visitor capacity. We do not use
          these technologies to capture any personal information about you.
          <br />
          You can choose to accept or decline cookies. Most web browsers
          automatically accept cookies, but you can usually modify your browser
          setting to decline cookies if you prefer. This may prevent you from
          taking full advantage of the website.
        </p>
        <h2>Security</h2>
        <p>
          The security of your personal information is of utmost concern to
          ProInsuranceHub. We exercise care in providing secure transmission of
          your personal information, and we take commercially reasonable steps
          to maintain the security of this information. While we strive to
          protect your personal information, we cannot ensure or warrant the
          security of any information you voluntarily transmit to us, and you do
          so at your own risk.
        </p>
        <p>
          ProInsuranceHub may store data on servers provided by third-party
          hosting vendors with whom we have contracted.
        </p>
        <h2>Contacting You</h2>
        <p>
          ProInsuranceHub may contact you via email or text/sms to inform you of
          new products or services, to send information we think you may find
          educational or interesting, or to ask for feedback. You will have the
          opportunity to opt out of emails or texts/sms by notifying us or
          choosing to unsubscribe.
        </p>
        <h2>Third-Party Links</h2>
        <p>
          This website may contain links to third-party web sites that are not
          controlled by ProInsuranceHub. These third-party links are made
          available to you as a convenience and you agree to use these links at
          your own risk. Please be aware that ProInsuranceHub is not responsible
          for the content of third-party websites linked to Health Plan Options
          Today/Garland Financial Group, nor are we responsible for the privacy
          policy or practices of third-party websites linked to ProInsuranceHub.
        </p>
        <p>
          Our privacy policy only applies to information we collect from you
          while you are at and while you are actively in a session with
          ProInsuranceHub. If you should link to a third-party website from
          ProInsuranceHub, we strongly encourage you to review and become
          familiar with the website privacy policy.
        </p>
        <h2 id="infoagreement">
          What You Agree to When You Share Your Information With Us
        </h2>
        <p>
          I agree to be contacted through automated means (e.g. autodialing,
          text and pre-recorded messaging) via telephone, mobile device
          (including SMS and MMS) and/or email, even if it is a cellular phone
          number or other service for which the called/messaged person(s) could
          be charged for such contact &amp; even if your telephone number is
          currently listed on any state, federal or corporate Do Not Call
          registry. Consent is not required to purchase goods or services.
          You’ll receive approximately 4 messages every month. Message &amp;
          Data Rates May Apply. REPLY “HELP” FOR HELP, REPLY “STOP” TO CANCEL.
          You agree to Terms &amp; Conditions and Privacy Policy.
        </p>
        <h2>Your privacy responsibilities:</h2>
        <h3>To help protect your privacy:</h3>
        <ul>
          <li>Log off the ProInsuranceHub website when you are finished.</li>
          <li>
            Take precautions to guard against “malware” (viruses), such as
            installing and updating suitable anti-virus software.
          </li>
        </ul>
        <p>
          ProInsuranceHub may change this Privacy Policy occasionally by
          updating this page. We reserve the right to change this Privacy Policy
          without providing you with advance notice of our intent to make the
          changes. If you do not agree to the terms of this Privacy Policy,
          please do not use this website.
        </p>
        <br />
        <p>
          The effective date of this Privacy Policy is July 10, 2024. If you
          have any questions about our privacy policy, please feel free to
          contact us at info@ProInsuranceHub.com.
        </p>
      </div>
    </>
  );
};

export default Privacy;
