import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { MdExpandMore as ExpandMoreIcon } from "react-icons/md";

const AccordionComp = () => {
  return (
    <div>
      <Accordion className="accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            Who is Eligible for Medicare Supplemental Insurance?
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion-details">
          <Typography>
            <p>
              If any of the following scenarios apply to you, then you are
              already guaranteed "eligible" to purchase Medicare supplemental
              <br />
              insurance:
            </p>
            <ul>
              <li>You are currently covered under Medicare Parts A &amp; B</li>
              <li>You are within 6 months of turning 65</li>
              <li>You are within 6 months of receiving Part B coverage</li>
              <li>If you are about to lose your group health insurance plan</li>
            </ul>
            <p>
              Please note that you may still be required to answer some medical
              questions about your overall health and wellness.
              <br />
              For further clarification, or if you have any questions, please do
              not hesitate to contact us at
              <br />
              <span class="ringpool brackets">1-307-243-3424</span>, TTY Users:
              711. Give us a call today.
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>What is Medicare Supplemental Insurance?</Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion-details">
          <Typography>
            <p>
              When an American citizen turns 65 years of age, they have the
              option to enroll in Medicare (they may also qualify before the age
              of 65 if they are eligible for disability benefits). Basic
              Medicare, which includes Parts A and B, cover most of your basic
              hospital and doctor expenses, respectively. Unfortunately, "most"
              does not mean "all". The expenses which Medicare does not cover -
              sometimes referred to as "gaps" in coverage - are what Medigap
              insurance protects you from. It is a private plan designed to
              cover the medical expenses where Traditional Medicare falls short
              of the mark.
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Is it illegal to drive without insurance?</Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion-details">
          <Typography>
            Yes, It is true that it is against the law in the majority of states
            to drive without insurance. Nevertheless, there are a few states
            that provide you with the option to be self-insured.
            <br /> In order to qualify for self-insurance, you will need to
            either make a cash deposit or post a surety bond with the treasury
            of your state. This sum is often equivalent to the individual
            responsibility limitations imposed by the state, which may range
            anywhere from tens of thousands to hundreds of thousands of dollars.
            As a result of this, self-insurance is not a choice that can be made
            by the majority of common people.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>
            What happens if you get caught driving without insurance?
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion-details">
          <Typography>
            Even if it’s your first time breaking the law, being pulled over
            while driving without insurance almost guarantees you a ticket with
            associated consequences, even if it’s your first offense.
            <br /> In certain places, such as Massachusetts, New Jersey, West
            Virginia, and Hawaii, the maximum amount that a driver may be fined
            for driving a car without insurance is $5,000. Even if it’s your
            first time breaking the law, your driver’s license might be
            suspended in several states if you drive without car insurance.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AccordionComp;
